
import Pallet from "./Pallet";
import { FillTypes } from "./Types";

export const ThemeHandler: FillTypes = {
    disable: {
        "--color": Pallet.grayForLine,
        "--hover": Pallet.grayForLine,
        "--borderColor": Pallet.grayForLine,
        "--title": Pallet.grayBaseForText,
        "--titleHover": Pallet.grayBaseForText, 
        "--circle": Pallet.grayBaseForText,
        "--circleHover": Pallet.grayBaseForText,
        "--circleIconColor": Pallet.grayForBox,
        "--circleIconHover": Pallet.grayForBox,
        "--icon": Pallet.grayBaseForText,
        "--iconHover": Pallet.grayBaseForText,
    },
    
    outline: {
        primary: {
            "--color": "transparent",
            "--hover": Pallet.primaryBase,
            "--borderColor": Pallet.primaryBase,
            "--title": Pallet.primaryBase,
            "--titleHover": "#fff",
            "--circle": Pallet.primaryBase,
            "--circleHover": "#fff",
            "--circleIconColor": "#fff",
            "--circleIconHover": Pallet.primaryBase,
            "--icon": Pallet.primaryBase,
            "--iconHover": "#fff",
        },
        secondary: {
            "--color": "transparent",
            "--hover": Pallet.secondaryBase,
            "--borderColor": Pallet.secondaryBase,
            "--title": Pallet.secondaryBase,
            "--titleHover": "#fff", 
            "--circle": Pallet.secondaryBase,
            "--circleHover": "#fff",
            "--circleIconColor": "#fff",
            "--circleIconHover": Pallet.secondaryBase,
            "--icon": Pallet.secondaryBase,
            "--iconHover": "#fff",
        },
        tertiary: {
            "--color": "transparent",
            "--hover": Pallet.tertiaryBase,
            "--borderColor": Pallet.tertiaryBase,
            "--title": Pallet.tertiaryBase,
            "--titleHover": "#fff", 
            "--circle": Pallet.tertiaryBase,
            "--circleHover": "#fff",
            "--circleIconColor": "#fff",
            "--circleIconHover": Pallet.tertiaryBase,
            "--icon": Pallet.tertiaryBase,
            "--iconHover": "#fff",
        },
        success: {
            "--color": "transparent",
            "--hover": Pallet.successBase,
            "--borderColor": Pallet.successBase,
            "--title": Pallet.successBase,
            "--titleHover": "#fff", 
            "--circle": Pallet.successBase,
            "--circleHover": "#fff",
            "--circleIconColor": "#fff",
            "--circleIconHover": Pallet.successBase,
            "--icon": Pallet.successBase,
            "--iconHover": "#fff",
        },
        danger: {
            "--color": "transparent",
            "--hover": Pallet.dangerBase,
            "--borderColor": Pallet.dangerBase,
            "--title": Pallet.dangerBase,
            "--titleHover": "#fff", 
            "--circle": Pallet.dangerBase,
            "--circleHover": "#fff",
            "--circleIconColor": "#fff",
            "--circleIconHover": Pallet.dangerBase,
            "--icon": Pallet.dangerBase,
            "--iconHover": "#fff",
        },
        gray: {
            "--color": "transparent",
            "--hover": Pallet.grayHoverForText,
            "--borderColor": Pallet.grayHoverForText,
            "--title": Pallet.grayHoverForText,
            "--titleHover": "#fff", 
            "--circle": Pallet.grayHoverForText,
            "--circleHover": "#fff",
            "--circleIconColor": "#fff",
            "--circleIconHover": Pallet.grayHoverForText,
            "--icon": Pallet.grayHoverForText,
            "--iconHover": "#fff",
        },
        white: {
            "--color": "transparent",
            "--hover": "#fff",
            "--borderColor": "#fff",
            "--title": "#fff",
            "--titleHover": "#000", 
            "--circle": Pallet.grayHoverForText,
            "--circleHover": "#fff",
            "--circleIconColor": "#fff",
            "--circleIconHover": Pallet.grayHoverForText,
            "--icon": Pallet.grayHoverForText,
            "--iconHover": "#fff",
        },

    },

    info: {
        primary: {
            "--color": Pallet.primaryLight,
            "--hover": Pallet.primaryBase,
            "--borderColor": "#fff",
            "--title": Pallet.primaryBase,
            "--titleHover": "#fff",
            "--circle": Pallet.primaryBase,
            "--circleHover": "#fff",
            "--circleIconColor": "#fff",
            "--circleIconHover": Pallet.primaryBase,
            "--icon": Pallet.primaryBase,
            "--iconHover": "#fff",
        },
        secondary: {
            "--color": Pallet.secondaryLight,
            "--hover": Pallet.secondaryBase,
            "--borderColor": "#fff",
            "--title": Pallet.secondaryBase,
            "--titleHover": "#fff",
            "--circle": Pallet.secondaryBase,
            "--circleHover": "#fff",
            "--circleIconColor": "#fff",
            "--circleIconHover": Pallet.secondaryBase,
            "--icon": Pallet.secondaryBase,
            "--iconHover": "#fff",
        },
        tertiary: {
            "--color": Pallet.tertiaryLight,
            "--hover": Pallet.tertiaryBase,
            "--borderColor": "#fff",
            "--title": Pallet.tertiaryBase,
            "--titleHover": "#fff",
            "--circle": Pallet.tertiaryBase,
            "--circleHover": "#fff",
            "--circleIconColor": "#fff",
            "--circleIconHover": Pallet.tertiaryBase,
            "--icon": Pallet.tertiaryBase,
            "--iconHover": "#fff",
        },
        success: {
            "--color": Pallet.successLight,
            "--hover": Pallet.successBase,
            "--borderColor": "#fff",
            "--title": Pallet.successBase,
            "--titleHover": "#fff",
            "--circle": Pallet.successBase,
            "--circleHover": "#fff",
            "--circleIconColor": "#fff",
            "--circleIconHover": Pallet.successBase,
            "--icon": Pallet.successBase,
            "--iconHover": "#fff",
        },
        danger: {
            "--color": Pallet.dangerLight,
            "--hover": Pallet.dangerBase,
            "--borderColor": "#fff",
            "--title": Pallet.dangerBase,
            "--titleHover": "#fff",
            "--circle": Pallet.dangerBase,
            "--circleHover": "#fff",
            "--circleIconColor": "#fff",
            "--circleIconHover": Pallet.dangerBase,
            "--icon": Pallet.dangerBase,
            "--iconHover": "#fff",
        },
        gray: {
            "--color": Pallet.grayLightForText,
            "--hover": Pallet.grayHoverForText,
            "--borderColor": "#fff",
            "--title": Pallet.grayHoverForText,
            "--titleHover": "#fff",
            "--circle": Pallet.grayHoverForText,
            "--circleHover": "#fff",
            "--circleIconColor": "#fff",
            "--circleIconHover": Pallet.grayHoverForText,
            "--icon": Pallet.grayHoverForText,
            "--iconHover": "#fff",
        },
        white: {
            "--color": Pallet.grayLightForText,
            "--hover": Pallet.grayHoverForText,
            "--borderColor": "#fff",
            "--title": Pallet.grayHoverForText,
            "--titleHover": "#fff",
            "--circle": Pallet.grayHoverForText,
            "--circleHover": "#fff",
            "--circleIconColor": "#fff",
            "--circleIconHover": Pallet.grayHoverForText,
            "--icon": Pallet.grayHoverForText,
            "--iconHover": "#fff",
        },
    },

    light: {
        primary: {
            "--color": "#fff",
            "--hover": "#fff",
            "--borderColor": "#fff",
            "--title": Pallet.primaryBase,
            "--titleHover": Pallet.primaryHover,
            "--circle": Pallet.primaryBase,
            "--circleHover": Pallet.primaryHover,
            "--circleIconColor": "#fff",
            "--circleIconHover": "#fff",
            "--icon": Pallet.primaryBase,
            "--iconHover": Pallet.primaryHover,
        },
        secondary: {
            "--color": "#fff",
            "--hover": "#fff",
            "--borderColor": "#fff",
            "--title": Pallet.secondaryBase,
            "--titleHover": Pallet.secondaryHover,
            "--circle": Pallet.secondaryBase,
            "--circleHover": Pallet.secondaryHover,
            "--circleIconColor": "#fff",
            "--circleIconHover": "#fff",
            "--icon": Pallet.secondaryBase,
            "--iconHover": Pallet.secondaryHover,
        },
        tertiary: {
            "--color": "#fff",
            "--hover": "#fff",
            "--borderColor": "#fff",
            "--title": Pallet.tertiaryBase,
            "--titleHover": Pallet.tertiaryHover,
            "--circle": Pallet.tertiaryBase,
            "--circleHover": Pallet.tertiaryHover,
            "--circleIconColor": "#fff",
            "--circleIconHover": "#fff",
            "--icon": Pallet.tertiaryBase,
            "--iconHover": Pallet.tertiaryHover,
        },
        success: {
            "--color": "#fff",
            "--hover": "#fff",
            "--borderColor": "#fff",
            "--title": Pallet.successBase,
            "--titleHover": Pallet.successHover,
            "--circle": Pallet.successBase,
            "--circleHover": Pallet.successHover,
            "--circleIconColor": "#fff",
            "--circleIconHover": "#fff",
            "--icon": Pallet.successBase,
            "--iconHover": Pallet.successHover,
        },
        danger: {
            "--color": "#fff",
            "--hover": "#fff",
            "--borderColor": "#fff",
            "--title": Pallet.dangerBase,
            "--titleHover": Pallet.dangerHover,
            "--circle": Pallet.dangerBase,
            "--circleHover": Pallet.dangerHover,
            "--circleIconColor": "#fff",
            "--circleIconHover": "#fff",
            "--icon": Pallet.dangerBase,
            "--iconHover": Pallet.dangerHover,
        },
        gray: {
            "--color": "#fff",
            "--hover": "#fff",
            "--borderColor": "#fff",
            "--title": Pallet.grayBaseForText,
            "--titleHover": Pallet.grayHoverForText,
            "--circle": Pallet.grayBaseForText,
            "--circleHover": Pallet.grayHoverForText,
            "--circleIconColor": "#fff",
            "--circleIconHover": "#fff",
            "--icon": Pallet.grayBaseForText,
            "--iconHover": Pallet.grayHoverForText,
        },
        white: {
            "--color": "#fff",
            "--hover": "#fff",
            "--borderColor": "#fff",
            "--title": Pallet.grayBaseForText,
            "--titleHover": Pallet.grayHoverForText,
            "--circle": Pallet.grayBaseForText,
            "--circleHover": Pallet.grayHoverForText,
            "--circleIconColor": "#fff",
            "--circleIconHover": "#fff",
            "--icon": Pallet.grayBaseForText,
            "--iconHover": Pallet.grayHoverForText,
        },
    },

    basic: {
        primary: {
            "--color": Pallet.primaryBase,
            "--hover": Pallet.primaryHover,
            "--borderColor": Pallet.primaryHover,
            "--title": "#fff",
            "--titleHover": "#fff",
            "--circle": "#fff",
            "--circleHover": "#fff",
            "--circleIconColor": Pallet.primaryBase,
            "--circleIconHover": Pallet.primaryHover,
            "--icon": "#fff",
            "--iconHover": "#fff",
        },
        secondary: {
            "--color": Pallet.secondaryBase,
            "--hover": Pallet.secondaryHover,
            "--borderColor": Pallet.secondaryHover,
            "--title": "#fff",
            "--titleHover": "#fff", 
            "--circle": "#fff",
            "--circleHover": "#fff",
            "--circleIconColor": Pallet.secondaryBase,
            "--circleIconHover": Pallet.secondaryHover,
            "--icon": "#fff",
            "--iconHover": "#fff",
        },
        tertiary: {
            "--color": Pallet.tertiaryBase,
            "--hover": Pallet.tertiaryHover,
            "--borderColor": Pallet.tertiaryHover,
            "--title": "#fff",
            "--titleHover": "#fff", 
            "--circle": "#fff",
            "--circleHover": "#fff",
            "--circleIconColor": Pallet.tertiaryBase,
            "--circleIconHover": Pallet.tertiaryHover,
            "--icon": "#fff",
            "--iconHover": "#fff",
        },
        success: {
            "--color": Pallet.successBase,
            "--hover": Pallet.successHover,
            "--borderColor": Pallet.successHover,
            "--title": "#fff",
            "--titleHover": "#fff", 
            "--circle": "#fff",
            "--circleHover": "#fff",
            "--circleIconColor": Pallet.successBase,
            "--circleIconHover": Pallet.successHover,
            "--icon": "#fff",
            "--iconHover": "#fff",
        },
        danger: {
            "--color": Pallet.dangerBase,
            "--hover": Pallet.dangerHover,
            "--borderColor": Pallet.dangerHover,
            "--title": "#fff",
            "--titleHover": "#fff", 
            "--circle": "#fff",
            "--circleHover": "#fff",
            "--circleIconColor": Pallet.dangerBase,
            "--circleIconHover": Pallet.dangerHover,
            "--icon": "#fff",
            "--iconHover": "#fff",
        },
        gray: {
            "--color": Pallet.grayBaseForText,
            "--hover": Pallet.grayHoverForText,
            "--borderColor": Pallet.grayHoverForText,
            "--title": "#fff",
            "--titleHover": "#fff", 
            "--circle": "#fff",
            "--circleHover": "#fff",
            "--circleIconColor": Pallet.grayBaseForText,
            "--circleIconHover": Pallet.grayHoverForText,
            "--icon": "#fff",
            "--iconHover": "#fff",
        },
        white: {
            "--color": Pallet.grayBaseForText,
            "--hover": Pallet.grayHoverForText,
            "--borderColor": Pallet.grayHoverForText,
            "--title": "#fff",
            "--titleHover": "#fff", 
            "--circle": "#fff",
            "--circleHover": "#fff",
            "--circleIconColor": Pallet.grayBaseForText,
            "--circleIconHover": Pallet.grayHoverForText,
            "--icon": "#fff",
            "--iconHover": "#fff",
        },
    },

    transparent: {
        primary: {
            "--color": "transparent",
            "--hover": "transparent",
            "--borderColor": "#fff",
            "--title": Pallet.primaryBase,
            "--titleHover": Pallet.primaryHover,
            "--circle": Pallet.primaryBase,
            "--circleHover": Pallet.primaryHover,
            "--circleIconColor": "#fff",
            "--circleIconHover": "#fff",
            "--icon": Pallet.primaryBase,
            "--iconHover": Pallet.primaryHover,
        },
        secondary: {
            "--color": "transparent",
            "--hover": "transparent",
            "--borderColor": "#fff",
            "--title": Pallet.secondaryBase,
            "--titleHover": Pallet.secondaryHover,
            "--circle": Pallet.secondaryBase,
            "--circleHover": Pallet.secondaryHover,
            "--circleIconColor": "#fff",
            "--circleIconHover": "#fff",
            "--icon": Pallet.secondaryBase,
            "--iconHover": Pallet.secondaryHover,
        },
        tertiary: {
            "--color": "transparent",
            "--hover": "transparent",
            "--borderColor": "#fff",
            "--title": Pallet.tertiaryBase,
            "--titleHover": Pallet.tertiaryHover,
            "--circle": Pallet.tertiaryBase,
            "--circleHover": Pallet.tertiaryHover,
            "--circleIconColor": "#fff",
            "--circleIconHover": "#fff",
            "--icon": Pallet.tertiaryBase,
            "--iconHover": Pallet.tertiaryHover,
        },
        success: {
            "--color": "transparent",
            "--hover": "transparent",
            "--borderColor": "#fff",
            "--title": Pallet.successBase,
            "--titleHover": Pallet.successHover,
            "--circle": Pallet.successBase,
            "--circleHover": Pallet.successHover,
            "--circleIconColor": "#fff",
            "--circleIconHover": "#fff",
            "--icon": Pallet.successBase,
            "--iconHover": Pallet.successHover,
        },
        danger: {
            "--color": "transparent",
            "--hover": "transparent",
            "--borderColor": "#fff",
            "--title": Pallet.dangerBase,
            "--titleHover": Pallet.dangerHover,
            "--circle": Pallet.dangerBase,
            "--circleHover": Pallet.dangerHover,
            "--circleIconColor": "#fff",
            "--circleIconHover": "#fff",
            "--icon": Pallet.dangerBase,
            "--iconHover": Pallet.dangerHover,
        },
        gray: {
            "--color": "transparent",
            "--hover": "transparent",
            "--borderColor": "#fff",
            "--title": Pallet.grayBaseForText,
            "--titleHover": Pallet.grayHoverForText,
            "--circle": Pallet.grayBaseForText,
            "--circleHover": Pallet.grayHoverForText,
            "--circleIconColor": "#fff",
            "--circleIconHover": "#fff",
            "--icon": Pallet.grayBaseForText,
            "--iconHover": Pallet.grayHoverForText,
        },
        white: {
            "--color": "transparent",
            "--hover": "transparent",
            "--borderColor": "#fff",
            "--title": "#fff",
            "--titleHover": "#fff",
            "--circle": Pallet.grayBaseForText,
            "--circleHover": Pallet.grayHoverForText,
            "--circleIconColor": "#fff",
            "--circleIconHover": "#fff",
            "--icon": Pallet.grayBaseForText,
            "--iconHover": Pallet.grayHoverForText,
        },
    }
}
